/* eslint-disable no-undef */
import { useEffect } from 'react';

function useEventListener<T = Event>(
  eventName: keyof WindowEventMap,
  handler: (event: T) => void,
  enabled: boolean = true,
) {
  useEffect(() => {
    if (enabled) {
      // @ts-ignore
      window.addEventListener(eventName, handler);
    } else {
      // @ts-ignore
      window.removeEventListener(eventName, handler);
    }

    return () => {
      // @ts-ignore
      window.removeEventListener(eventName, handler);
    };
  }, [eventName, handler, enabled]);
}

export default useEventListener;
