import React, { CSSProperties, FunctionComponent } from 'react';
import { ErrorMessage } from './ValidationError.style';

interface Props {
  error?: string | false;
  containerStyle?: CSSProperties;
}

const ValidationError: FunctionComponent<Props> = (props) => {
  if (!props.error) {
    return null;
  }

  return (
    <ErrorMessage style={props.containerStyle} data-testid="validation-error">
      {props.error}
    </ErrorMessage>
  );
};

export default ValidationError;
