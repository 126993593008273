/* global window document */
import { useEffect, useState } from 'react';

interface Size {
  width?: number;
  height?: number;
}

const useWindowSize = (fixed: boolean = true) => {
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: document.body.clientWidth,
        // innerHeight changes as a user scrolls on mobile browsers (caused by the nav bar shifting)
        height: fixed ? document.body.clientHeight : window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export default useWindowSize;
