import styled from 'styled-components';

// for changing styles of rich text elements
export const Content = styled.div`
  font-family: inherit !important;

  p {
    margin: 15px 0;
  }

  ul,
  ol {
    padding: 0 0 0 20px;

    li::marker {
      color: ${({ theme }) => theme.primary};
    }

    li {
      padding: 10px 0px;
    }
  }

  a {
    color: ${({ theme }) => theme.primary};
    text-decoration: underline;

    &:hover {
      color: ${({ theme }) => theme.buttonHover};
    }
  }

  img {
    width: 100%;
  }

  blockquote {
    margin: 1em 0;
    padding: 0 0 0 1em;
    border-left: 2px solid ${({ theme }) => theme.primary};
    unicode-bidi: -webkit-plaintext;
  }

  hr {
    max-width: 280px;
    border: none;
    border-top: 1px dashed ${({ theme }) => theme.primary};
    margin: 40px auto;
  }

  * {
    font-family: inherit !important;
  }
`;

export default Content;
