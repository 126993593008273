import styled from 'styled-components';

export const ErrorMessage = styled.p`
  display: inline-flex;
  align-self: flex-start;
  font-size: 14px;
  background-color: ${(props) => props.theme.primary};
  padding: 3px 12px;
  color: ${(props) => props.theme.white};
`;

export default ErrorMessage;
