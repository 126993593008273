import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Label = styled.label`
  display: block;
  color: ${(props) => props.theme.textDark};
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const Input = styled.input<{
  disabled?: boolean;
  error?: string | false;
  icon?: any;
  rounded?: boolean;
  borderColour?: string;
}>`
  background: ${(props) => (props.disabled ? props.theme.backgroundLighter : props.theme.background)};
  height: 56px;
  color: ${(props) => (props.disabled ? props.theme.backgroundMid : props.theme.textDark)};
  font-size: 16px;
  padding: 0 15px 0 ${({ icon }) => (icon ? 54 : 15)}px;
  box-shadow: inset 0 0 0
    ${(props) => (props.error ? `2px ${props.theme.primary}` : `1px ${props.borderColour || props.theme.keyline}`)};
  border: none;
  min-width: 0;
  width: 100%;
  -webkit-appearance: none;
  border-radius: 0;

  &::placeholder {
    color: ${(props) => props.theme.backgroundMid};
  }

  ${(props) =>
    props.rounded &&
    `
    border-radius: 6px;
    box-shadow: none;
  `}

  ${(props) =>
    props.rounded &&
    props.error &&
    `
    border: 2px solid ${props.theme.primary};
    padding: 0 13px 0 ${props.icon ? 52 : 13}px;
    border-radius: 6px 6px 6px 0;
  `};

  &::-webkit-search-decoration,
  ::-webkit-search-cancel-button,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration {
    display: none;
  }
`;

export const AutoCompleteContainer = styled.div`
  position: relative;
`;

export const AutoCompleteBox = styled.div`
  position: absolute;
  width: 100%;
  border-left: 1px solid ${(props) => props.theme.keyline};
  border-right: 1px solid ${(props) => props.theme.keyline};
  z-index: 20;
  background-color: ${(props) => props.theme.backgroundLightest};
  max-height: 400px;
  overflow-y: scroll;
`;

export const AutoCompleteItem = styled.button`
  width: 100%;
  padding: 15px;
  border-bottom: 1px solid ${(props) => props.theme.keyline};
  font-size: 16px;
  color: ${(props) => props.theme.textDark};
  text-align: left;

  &:hover {
    background-color: ${(props) => props.theme.backgroundLighter};
  }
`;

export const Icon = styled.button`
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translate(21px, -50%);

  & svg {
    font-size: 22px;
  }
`;
