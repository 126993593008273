export const parseHex = (hex: string, delta: number) => {
  return Math.min(255, Math.max(0, parseInt(hex, 16) + delta));
};

export const hexToRgb = (hexCode: string, alpha: number, delta: number = 0): string => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexCode);

  if (result) {
    const r = parseHex(result[1], delta);
    const g = parseHex(result[2], delta);
    const b = parseHex(result[3], delta);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  return 'rgba(0,0,0,1)';
};
