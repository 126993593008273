import { laptopMaxXL, mobileXXLargeMax } from '@propertypal/shared/src/constants/mediaQueries';
import QuoteMark from '@propertypal/shared/src/resources/icons/content-hub/quote-mark.svg?url';
import { IArticleSubTitleArticleSubTitle, IArticleTag } from '@propertypal/shared/src/types/strapi';
import styled, { css } from 'styled-components';
import Button from '../buttons/Button';
import RichText from '../rich-text/RichText';
import { Text, TextLink } from '../typography';

export const NotFound = styled.div`
  padding: 15px 0;
  max-width: 700px;
  margin: 0 auto;
  text-align: center;

  & > * + * {
    margin-top: 16px;
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 16px;
  }
`;

export const ArticleRichText = styled(RichText)`
  text-align: left;
  margin-top: 10px;

  * {
    overflow-wrap: break-word;
    line-height: 1.6;
  }

  blockquote {
    line-height: 26px;
    background-color: rgba(238, 73, 0, 0.1);
    border-radius: 8px;
    border-left-width: 0px;
    padding: 50px 30px 25px;
    position: relative;
    margin-top: 35px;

    &::before {
      content: '';
      position: absolute;
      top: -35px;
      left: 30px;
      background: ${({ theme }) => theme.white};
      height: 70px;
      width: 70px;
      box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1);
      border-radius: 100%;
    }

    &::after {
      content: '';
      position: absolute;
      top: -15px;
      left: 47px;
      width: 35px;
      height: 32px;
      background-image: url(${QuoteMark.src});
    }

    * {
      line-height: 26px;
    }
  }

  figure {
    max-width: 100%;

    &:not(.image-style-side):not(.image_resized) {
      margin: 0;
      width: auto !important;
    }

    &.image-style-side {
      float: right;
      margin-right: 0;
    }

    &.image_resized {
      max-width: 100%;
    }
  }

  hr {
    max-width: none;
    border-color: ${({ theme }) => theme.backgroundLight};
    border-style: solid;
    border-width: 1px;
  }

  a {
    span,
    p {
      transition: color 150ms linear;
      &:hover {
        color: ${({ theme }) => theme.buttonHover} !important;
      }
    }
  }
`;

export const KeyHighlightsText = styled(RichText)``;

export const ArticleAuthor = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  margin-bottom: 35px;
`;

export const AuthorPic = styled.img`
  border-radius: 100%;
  height: 84px;
  width: 84px;
  object-fit: cover;
`;

export const KeyHighlightsContainer = styled.div`
  margin: 40px 0;
`;

export const KeyHighlights = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, minmax(0, 1fr));

  @media (${laptopMaxXL}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (${mobileXXLargeMax}) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

export const KeyHighlight = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${({ theme }) => theme.backgroundLighter};
  padding: 30px 15px;
  border-radius: 8px;
  gap: 10px;
  text-align: center;

  img {
    height: 60px;
    margin-bottom: 10px;
  }
`;

export const KeyHighlightTitle = styled.div``;

export const KeyHighlightDesc = styled.div`
  flex: 0 0 auto;
`;

export const KeyHighlightTitleText = styled(Text)`
  font-size: 30px;
  font-weight: bold;
  color: ${({ theme }) => theme.primary};

  @media (${laptopMaxXL}) {
    font-size: 26px;
  }

  @media (${mobileXXLargeMax}) {
    font-size: 22px;
  }
`;

export const BackToContainer = styled.div`
  width: fit-content;
  margin-top: 35px;
`;

export const BackToLink = styled(TextLink)`
  display: flex;
  gap: 5px;
  align-items: center;

  span {
    line-height: 1rem;
  }
`;

export const ArticleTag = styled.div<{
  bg?: IArticleTag['attributes']['backgroundColour'];
  colour?: IArticleTag['attributes']['textColour'];
  mb?: number;
}>`
  padding: 3px 5px;
  border-radius: 2px;
  background-color: ${({ bg, theme }) => bg || theme.primary};
  color: ${({ colour, theme }) => colour || theme.white};
  font-size: 11px;
  font-weight: 600;
  display: inline-block;
  ${({ mb }) =>
    mb != null
      ? css`
          margin-bottom: ${mb}px;
        `
      : ''}
`;

export const ArticleSubtitle = styled.span<{
  colour: IArticleSubTitleArticleSubTitle['colour'];
  italicise: IArticleSubTitleArticleSubTitle['italicise'];
}>`
  display: inline;
  ${({ colour }) =>
    colour
      ? css`
          color: ${colour};
        `
      : ''}
  ${({ italicise }) =>
    italicise
      ? css`
          font-style: italic;
        `
      : ''}
`;

export const ArticleButtonLink = styled.a`
  margin-top: 15px;
  display: inline-block;
`;

export const ArticleButton = styled(Button)`
  width: auto;
  padding: 0 20px;
  max-width: 100%;
  min-width: 220px;
`;
