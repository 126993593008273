import { hexToRgb } from '@propertypal/shared/src/utils/colour';
import Link from 'next/link';
import styled from 'styled-components';
import { ArticleTag } from '../article/Article.style';

export const NewsBox = styled(Link)`
  display: block;
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative;

  &:hover {
    background-color: ${({ theme }) => theme.backgroundLightest};
  }

  ${ArticleTag} {
    position: absolute;
    left: 0;
    top: 10px;
  }
`;

export const NewsImage = styled.div<{ image?: string }>`
  width: 100%;
  aspect-ratio: 4/3;
  background-size: cover;
  background-image: url(${({ image }) => image});
  background-position: center;
`;

export const NewsContent = styled.div<{ readMoreBtn?: boolean }>`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: space-between;
  height: ${({ readMoreBtn }) => (readMoreBtn ? 270 : 250)}px;

  h3 {
    color: ${({ theme }) => theme.textDark};
    font-size: 22px;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  p {
    color: ${({ theme }) => theme.textDark};
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
  }

  > p:last-child {
    color: ${({ theme }) => theme.primary};
    text-decoration: underline;
  }
`;

export const ReadMoreBtn = styled.span`
  display: inline-flex;
  height: 54px;
  border-radius: 8px;
  align-items: center;
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.white};
  padding: 16px 22px;
  align-self: start;
  font-weight: 600;
  transition: background-color 150ms linear;

  &:hover {
    background-color: ${({ theme }) => hexToRgb(theme.primary, 1, -55)};
  }
`;
