import { mobileMax, mobileXLargeMax, tabletMax } from '@propertypal/shared/src/constants/mediaQueries';
import styled from 'styled-components';

export interface DefaultProps {
  m?: number | string;
  mt?: number;
  ml?: number;
  mr?: number;
  mb?: number;
  mv?: number;
  mh?: number;
  p?: number | string;
  pt?: number;
  pl?: number;
  pr?: number;
  pb?: number;
  pv?: number;
  ph?: number;
  f?: number;
  br?: number | string;
  w?: string;
  h?: string;
  b?: string;
  bTop?: string;
  bBottom?: string;
  bLeft?: string;
  bRight?: string;
  ta?: string;
  cursor?: string;
  maxWidth?: number | string;
  minWidth?: number;
  maxHeight?: number;
  minHeight?: number;
  gridGap?: number;
  visible?: boolean;
  display?: string;
  bgColour?: string;
  background?: string;
  alignItems?: string;
  gap?: number;
  alignSelf?: string;
  justifyContent?: string;
  flexFlow?: string;
  flexDirection?: string;
  flexBasis?: string;
  flex?: string;
  position?: string;
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  zIndex?: number;
  transform?: string;
}

interface UserSectionProps {
  padding?: number;
}

export const applyProps = (props: DefaultProps) => {
  let styles = '';

  if (props.m !== undefined) styles += `margin: ${props.m}${typeof props.m === 'string' ? '' : 'px'};`;
  if (props.mt !== undefined) styles += `margin-top: ${props.mt}px;`;
  if (props.ml !== undefined) styles += `margin-left: ${props.ml}px;`;
  if (props.mr !== undefined) styles += `margin-right: ${props.mr}px;`;
  if (props.mb !== undefined) styles += `margin-bottom: ${props.mb}px;`;

  if (props.p !== undefined) styles += `padding: ${props.p}${typeof props.p === 'string' ? '' : 'px'};`;
  if (props.pt !== undefined) styles += `padding-top: ${props.pt}px;`;
  if (props.pl !== undefined) styles += `padding-left: ${props.pl}px;`;
  if (props.pr !== undefined) styles += `padding-right: ${props.pr}px;`;
  if (props.pb !== undefined) styles += `padding-bottom: ${props.pb}px;`;
  if (props.br !== undefined) styles += `border-radius: ${props.br}${typeof props.br === 'string' ? '' : 'px'};`;

  if (props.f !== undefined) styles += `flex: ${props.f};`;
  if (props.w !== undefined) styles += `width: ${props.w};`;
  if (props.h !== undefined) styles += `height: ${props.h};`;
  if (props.b !== undefined) styles += `border: ${props.b};`;
  if (props.bTop !== undefined) styles += `border-top: ${props.bTop};`;
  if (props.bBottom !== undefined) styles += `border-bottom: ${props.bBottom};`;
  if (props.bLeft !== undefined) styles += `border-left: ${props.bLeft};`;
  if (props.bRight !== undefined) styles += `border-right: ${props.bRight};`;

  if (props.display !== undefined) styles += `display: ${props.display};`;
  if (props.maxWidth !== undefined)
    styles += `max-width: ${props.maxWidth}${typeof props.maxWidth === 'string' ? '' : 'px'};`;
  if (props.minWidth !== undefined) styles += `min-width: ${props.minWidth}px;`;
  if (props.maxHeight !== undefined) styles += `max-height: ${props.maxHeight}px;`;
  if (props.minHeight !== undefined) styles += `min-height: ${props.minHeight}px;`;
  if (props.gridGap !== undefined) styles += `grid-gap: ${props.gridGap}px;`;
  if (props.bgColour !== undefined) styles += `background-color: ${props.bgColour};`;
  if (props.background !== undefined) styles += `background: ${props.background};`;
  if (props.alignItems !== undefined) styles += `align-items: ${props.alignItems};`;
  if (props.alignSelf !== undefined) styles += `align-self: ${props.alignSelf};`;
  if (props.justifyContent !== undefined) styles += `justify-content: ${props.justifyContent};`;
  if (props.flexFlow !== undefined) styles += `flex-flow: ${props.flexFlow};`;
  if (props.flexDirection !== undefined) styles += `flex-direction: ${props.flexDirection};`;
  if (props.flexBasis !== undefined) styles += `flex-basis: ${props.flexBasis};`;
  if (props.flex !== undefined) styles += `flex: ${props.flex};`;
  if (props.ta !== undefined) styles += `text-align: ${props.ta};`;
  if (props.cursor !== undefined) styles += `cursor: ${props.cursor};`;
  if (props.zIndex !== undefined) styles += `z-index: ${props.zIndex};`;
  if (props.transform !== undefined) styles += `transform: ${props.transform};`;
  if (props.gap !== undefined) styles += `gap: ${props.gap}px;`;

  if (props.position !== undefined) styles += `position: ${props.position};`;
  if (props.top !== undefined) styles += `top: ${props.top};`;
  if (props.right !== undefined) styles += `right: ${props.right};`;
  if (props.bottom !== undefined) styles += `bottom: ${props.bottom};`;
  if (props.left !== undefined) styles += `left: ${props.left};`;

  if ('visible' in props && !props.visible) styles += `display:none`;

  return styles;
};

export const Box = styled.div<DefaultProps & { color?: string }>`
  width: 100%;
  ${(props) =>
    props.color &&
    `
    background-color: ${props.color};  
  `}
  ${applyProps}
`;

export const Row = styled.div<DefaultProps>`
  display: flex;
  flex-direction: row;
  ${applyProps}
`;

export const RowCenter = styled.div<DefaultProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${applyProps}
`;

export const RowAlignCenter = styled.div<DefaultProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${applyProps}
`;

export const RowWrap = styled.div<DefaultProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${applyProps}
`;

export const Column = styled.div<DefaultProps>`
  display: flex;
  flex-direction: column;
  ${applyProps}
`;

export const Hr = styled.hr<DefaultProps>`
  width: 100%;
  border: none;
  height: 1px;
  background-color: ${(props) => props.theme.backgroundLighter};
  ${applyProps}
`;

export const DashedHr = styled.div<DefaultProps & { borderWidth?: number }>`
  border-bottom: ${({ borderWidth }) => borderWidth || 2}px dashed ${(props) => props.theme.lightGrey};
  ${applyProps}
`;

export const UserSectionLayout = styled.section<DefaultProps & UserSectionProps>`
  display: flex;
  flex-flow: row;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.backgroundLightest};
  padding: ${({ padding }) => (padding != null ? padding : `45px 0`)};
  transition: all 300ms linear;

  @media screen and (${tabletMax}) {
    padding: 30px 0;
  }

  @media screen and (${mobileXLargeMax}) {
    padding: 15px 0;
  }

  @media screen and (${mobileMax}) {
    padding: 15px 0;
  }

  ${applyProps}
`;
