import React from 'react';
import { Content } from './RichText.style';

interface RichTextProps {
  html?: string | null;
  className?: string;
}
const RichText: React.FC<RichTextProps> = ({ html, className }) => {
  if (!html || (html && html.replace(/\s/g, '')) === '') return null;
  return <Content data-testid="rich-text" className={className} dangerouslySetInnerHTML={{ __html: html }} />;
};

export default RichText;
